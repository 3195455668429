@import "../../style/universal.scss";
.suggestions {
    list-style: none;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    min-width: 370px;
    min-height: 100px;
    background-color: $white;
    border-radius: 20px;
    position: absolute;
    overflow: hidden;
    padding: 10px 0 10px 0;
    left: 0;
    bottom: 100%;
    z-index: 3;
    box-shadow: 0px 4px 46px 4px rgba(0, 0, 0, 0.1);
    ul {
        border-radius: 20px;
    }
    li {
        &:hover {
            background-color: $background;
        }
        &:first-child {
            border-radius: 10px 10px 0 0;
        }
        &:last-child {
            border-radius: 0 0 10px 10px;
        }
        @include semiBoldFont;
        font-size: 15px;
        color: $dark;
        background-color: $white;
        display: flex;
        align-items: center;
        padding: 15px 15px 15px 20px;
        img {
            margin-right: 15px;
            margin-top: -5px;
            width: 20px;
            height: 20px;
        }
        div {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
        span {
            &:last-child {
                font-size: 14px;
                color: gray;
            }
        }
    }
}
@media only screen and (max-width: 650px) {
    .suggestions {
        bottom: 0;
        top: 100%;
        height: 80vh;
    }
}
