@import "../../style/universal.scss";
.button {
    // span {
    //     height: min-content;
    // }
    @include semiBoldFont;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 1.4;
    border: none;
    box-shadow: unset;

    // border-radius: 6px;
}
