@import "../../style/universal.scss";

.card {
    @include semiBoldFont;
    background: $white;
    padding: 5px 5px 10px 5px;
    box-sizing: border-box;
    border-radius: 20px;
    img {
        width: 100%;
        height: 90%;
        object-fit: cover;
        border-radius: 20px;
    }
    p {
        color: $dark;
        margin-top: 7px;
        margin-bottom: 7px;
        padding-left: 17px;
    }
}
