@import "../../style/universal.scss";

.flightOffer {
    @include semiBoldFont;
    position: relative;
    padding: 5%;
    border-radius: 20px;
    white-space: nowrap;
    width: 100%;
    min-width: 320px;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 4px 4px 30px 7px rgba(0, 0, 0, 0.03);
    margin-bottom: 20px;
}
.firstRow {
    width: 100%;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    .twoCircles {
        position: relative;
        height: 20px;
        width: 100%;
        margin: 20px 0;
    }
    svg {
        &:first-child {
            position: absolute;
            left: 0;
            z-index: 2;
        }
        &:nth-child(2) {
            position: absolute;
            right: 0;
            z-index: 2;
            float: right;
        }
        &:last-child {
            position: absolute;
            top: 7px;
            bottom: 0;
            z-index: 1;
            width: 100%;
            height: 2px;
        }
    }
    .dashedLine {
        border: 1px dashed #ff0000;
        border-style: none none dashed;
        color: #fff;
        background-color: #fff;
        width: 100%;
    }
    .details {
        @include normalFont;
        display: flex;
        align-items: flex-end;
    }
    .time {
        @include normalFont;
        flex-basis: 40%;
        margin-bottom: 5px;
    }
    .arrivalTime {
        text-align: end;
        flex-basis: 40%;
    }
}
.middleRow {
    svg {
        width: 100%;
    }
}
.lastRow {
    display: flex;
    justify-content: space-evenly;
    .airline {
        display: flex;
        align-items: flex-end;
        flex-grow: 1;
        font-size: 14px;
        .logo {
            width: 20%;
            min-width: 80px;
            margin-right: 20px;
            margin-bottom: 5px;
        }
    }
    .fare {
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-grow: 1;
        .class {
            @include normalFont;
            background-color: $background;
            height: 70%;
            display: flex;
            align-items: center;
            padding: 5px 10px 5px 10px;
            border-radius: 5px;
        }
        .price {
            margin-left: 10%;
            text-align: end;
            display: flex;
            flex-direction: column;
        }
    }
}
@media only screen and (max-width: 1024px) {
    .flightOffer {
        padding: 7%;
    }
    .firstRow {
        flex-wrap: wrap-reverse;
        .details {
            order: 2;
            flex-basis: 100%;
            // margin-bottom: 20px;
        }
        svg {
            order: 1;
        }
        .twoCircles {
            margin: 15px 0;
        }
    }
    .lastRow {
        margin-top: 20px;

        .fare {
            .class {
                position: absolute;
                top: 11%;
                right: 7%;
                background: none;
                padding: 0;
                height: min-content;
            }
        }
        .price {
            & > span {
                display: none;
            }
        }
    }
}
@media only screen and (max-width: 750px) {
}

@media only screen and (max-width: 650px) {
    .flightOffer {
        width: 100%;
    }
    .firstRow {
        flex-wrap: wrap-reverse;
        .details {
            order: 1;
            flex-basis: 100%;
        }
    }
    .lastRow {
        .fare {
            .class {
                position: absolute;
                top: 20px;
                right: 20px;
                height: 40px;
            }
        }
    }
} ;
