@import "../../style/universal.scss";
.navBar {
    @include semiBoldFont;
    z-index: 100000;
    width: 69%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    height: 64px;
    font-size: 15px;
    ul {
        display: flex;
        list-style: none;
        li {
            margin: 0 0 0 50px;
        }
        li.signIn {
            padding: 7px 20px 7px 20px;
            height: 90%;
            margin-top: -7px;
            border-radius: 24px;
            box-sizing: border-box;
            background-color: $white;
        }
        li:last-child {
            display: none;
        }
    }
}
@media only screen and (max-width: 1280px) {
    .navBar {
        width: 80%;
    }
}
@media only screen and (max-width: 1024px) {
    .navBar {
        width: 90%;
    }
}

@media only screen and (max-width: 650px) {
    .navBar {
        position: absolute;
        .logo {
            color: $dark;
        }
        ul {
            li {
                display: none;
            }
            li:last-child {
                display: inline-block;
            }
        }
    }
}
