@import "../../style/universal.scss";
.modal {
    z-index: 2;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: $background;
    overflow: auto;
}
