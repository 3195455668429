@import "../../style/universal.scss";

.searchResultsPage {
    display: flex;
    width: 70%;
    min-width: 1280px;
    background-color: #f7f9fc;
    box-shadow: 0px 4px 17px 5px rgba(0, 0, 0, 0.03);
    border-radius: 20px;
    .searchResults {
        padding: 4%;
        width: 70%;
        .filterAndSort {
            display: flex;
            gap: 20px;
        }
        .button {
            padding: 0 0 20px 0;
            color: $primary;

            svg {
                margin-right: 7px;
            }
        }
    }
}
.sideBar {
    @include semiBoldFont;
    min-width: 330px;
    width: 30%;
    padding: 2% 0 2% 3%;
    .lilTabs {
        display: flex;
        justify-content: center;
        gap: 50px;
        margin-bottom: 40px;
        margin-top: 20px;
        .lilTab.button {
            flex-direction: column;
            gap: 5px;
            color: $dark;
        }
    }

    #activeLilTab {
        color: $primary;
    }
    .searchTerms {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 10% 1% 0 10%;
        gap: 6%;
        label {
            @include normalFont;
            font-size: 14px;
        }
        input {
            width: 90%;
            margin-top: 3px;
            font-size: 15px;
        }
        .searchInput {
            display: flex;
            flex-direction: column;
            gap: 3px;
        }
        .lilSearchField {
            width: 40%;
            margin-bottom: 40px;
        }
        .lilHotelField {
            width: 100%;
        }
        .lilDateFields {
            width: 100%;
            gap: 6%;
            white-space: nowrap;
            display: flex;
            flex-wrap: wrap;
        }
    }
}

.sideSection {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10%;
    background-color: #ffff;
    border-radius: 20px;
    box-shadow: 0px 4px 17px 5px rgba(0, 0, 0, 0.03);
    margin-bottom: 20px;
}
.addExtra {
    .addHotel {
        margin-bottom: 15px;
    }
    input {
        margin: 0 10px 0 0;
    }
}
.updateSearchBtn {
    background-color: $primary;
    color: $white;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    float: right;
    gap: 5px;
}

.hotelOffer {
    @include normalFont;
    white-space: nowrap;
    background: #ffffff;
    box-shadow: 4px 4px 30px 7px rgba(0, 0, 0, 0.03);
    padding: 1%;
    border-radius: 20px;
    display: flex;
    margin-bottom: 20px;
    min-width: 550px;
    height: 230px;

    .hotelPicture {
        width: 42%;
        img {
            border-radius: 14px;
            object-fit: cover;
            display: block;
            max-height: 100%;
            height: 100%;
            width: 100%;
        }
    }
    .hotelDetails {
        display: flex;
        width: 47%;
        flex-wrap: wrap;
        justify-content: space-between;
        // gap: 15px;
        flex-grow: 1;
        padding: 20px;
        & > div {
            margin-bottom: 15px;
        }
        .lilAminities {
            order: 4;
            display: flex;
            width: 100%;
            gap: 20%;
            .lilAmenity {
                display: flex;
                align-items: center;
                gap: 10px;
                svg {
                    width: 17px;
                    height: 17px;
                }
            }
        }
        .reviews {
            order: 1;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 5px;
            svg {
                width: 18px;
                height: 18px;
                margin-bottom: 3px;
            }
        }
        .hotelInfo {
            order: 2;
            width: 100%;
            .hotelName {
                @include semiBoldFont;
                font-size: 17px;
                margin-bottom: 5px;
            }
        }

        .price {
            @include semiBoldFont;
            order: 6;
            display: flex;
            width: 30%;
            // font-size: 22px;
            text-align: end;
            flex-direction: column;
            align-items: flex-end;
            .per {
                span:nth-child(2) {
                    display: none;
                }
            }
        }
        .refundable {
            order: 5;
            width: 47%;
            color: #357266;
        }
    }
}
@media only screen and (max-width: 1280px) {
    .searchResultsPage {
        width: 95%;
        min-width: unset;
        .hotelPrice {
            flex-direction: column;
        }
    }
}

@media only screen and (max-width: 1024px) {
    .searchResultsPage {
        .sideBar {
            min-width: 280px;
            font-size: 14px;
            .lilTabs {
                gap: 35px;
                font-size: 14px;
            }
        }
    }
    .hotelOffer {
        display: flex;
        flex-direction: column;
        height: unset;
        min-width: unset;
        padding: 1.5%;
        .hotelDetails {
            width: 100%;
        }
        .hotelPicture {
            height: 30vw;
            width: 100%;
        }
    }
}
@media only screen and (max-width: 790px) {
    .searchResultsPage {
        flex-direction: column;
        background: none;
        box-shadow: unset;
        .sideBar {
            // display: none;
            .searchTerms {
                background: none;
                box-shadow: unset;
                gap: 0;
                flex-wrap: nowrap;
                padding: 2% 0 2% 0;
                overflow: auto;
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
                &::-webkit-scrollbar {
                    display: none;
                }
                .lilSearchField {
                    background-color: $white;
                    padding: 20px;
                    min-width: 130px;
                    margin-right: 20px;
                    border-radius: 20px;
                    margin-bottom: 0;
                }
                .lilHotelField {
                    min-width: 200px;
                }
                .lilDateFields {
                    flex-wrap: nowrap;
                }
            }
        }
        .searchResults {
            width: 100%;
            padding: 0;
        }
        .sideBar {
            width: 100%;
            padding: 0;
        }
    }
    .hotelOffer {
        background-color: $white;
        .hotelPicture {
            height: 40vw;
            width: 100%;
        }
    }
}
@media only screen and (max-width: 650px) {
    .sideBar {
        .lilTabs {
            margin-bottom: 20px;
            .lilTab {
                svg {
                    display: none;
                }
            }
        }
    }
    .searchResultsPage {
        margin-top: 70px;
        .hotelOffer {
            .hotelPicture {
                height: 55vw;
                width: 100%;
            }
        }
        .hotelDetails {
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;
            padding: 20px 20px 10px 20px;
            .reviews {
                order: 2;
                justify-content: flex-end;
                width: 55%;
                text-align: right;
            }
            .price {
                display: flex;
                flex-direction: row;
                order: 1;
                width: 44%;
                justify-content: flex-start;
                gap: 5px;
                .per {
                    @include normalFont;
                    display: flex;
                    gap: 5px;
                    span:first-child {
                        display: none;
                    }
                    span:nth-child(2) {
                        display: block;
                    }
                }
            }
            .address {
                opacity: 0.8;
            }
            .lilAminities {
                display: none;
            }
            .refundable {
                display: none;
            }
        }
    }
}
