@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
$primary: #4f96f7;
$dark: #0a1f38;
$white: #f7f9fc;
$background: #eff0f7;
#root {
    display: flex;
    z-index: 1;
    flex-direction: column;
    align-items: center;
    position: relative;
}
* {
    background: none;
    border: none;
    padding: 0;
    box-sizing: border-box;
}
input {
    &:focus {
        outline: none;
    }
}
html,
body {
    padding: 0;
    margin: 0;
    background-color: $background;
    color: $dark;
}

button {
    cursor: pointer;
}
a {
    text-decoration: none;
    color: $dark;
}
@mixin normalFont {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 400;
}
@mixin semiBoldFont {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 500;
}

@mixin boldFont {
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 800;
}
